.text-div{
  padding-top: 5vw;
  padding-left: 2vw;
  padding-right: 2vw;
}

.app-div{
  display: inline-block;
  width: 100vw;
}

.download-button{
  margin-bottom: 5vw;
}

.logo-pic {
  width: 70vw;
}

.logo-div {
  justify-content:center;
  display:flex;
  align-items: center;
}

.black-font{
  color: #000;
}