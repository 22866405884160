.pdf-download{
    font-size: 3vw;
    padding-left: 2vw;
    padding-bottom: 3vw;
    color: white;
    background-color: black;
}
.pdf-viewer{
    width: 100%;
    height: 50vw;
  }
  