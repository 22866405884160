.form-list{
    margin-top: 2vw;
}

.header-div {
    display: inline;
    padding-bottom: 2vw;
}

.information-div{
    padding-bottom: 2vw;
    padding-right: 2vw;
    width: 94vw;
}

.rental-div{
    margin-top: 3vw;
    margin-bottom: 4vw;
}

.condition-div{
    border: 0.25vw solid black;
    background-color: gray;
}

.register-form{
    margin-left: 1vw;
    margin-right: 1vw;
}

.form-list{
    list-style: none;
    display: grid;
    border: 0.25vw solid black;
    background-color: black;
    grid-template-columns: 20vw 10vw 66vw;
    grid-column-gap: 0.20vw;
    grid-row-gap: 0.20vw;
    padding-right: 0;
}

.header-title {
    display: inline;
}

.sub-header {
    font-size: 2vw;
    font-weight: bold;
}

.row-title{
    grid-row: auto;
    grid-column: auto;
    background-color: #fff;
    margin: 0;
    font-size: 2vw;
    padding-top: 0.5vw;
    padding-left: 1vw;
    padding-bottom: 0.25vw;
    height: 6vw;
}

.row-code{
    padding-left: 0.5vw;
    grid-row: auto;
    grid-column: auto;
    font-size: 2vw;
}

.row-comment{
    padding-left: 1vw;
    grid-row: auto;
    grid-column: auto;
    font-size: 2vw;
}

.row-comment-long{
    grid-row: auto;
    grid-column: auto;
    font-size: 2vw;
    width: 90%;
    padding-top: 1vw;
    padding-left: 1vw;
    padding-bottom: 1vw;
    margin-left: 2vw;
}

.header-list {
    display: inline-block;
}

.horizontal-list{
    display: inline-block;
    padding-right: 6vw;
}

.header-input{
    margin-left: 2vw;
    width: 30vw;
    height: 6vw;
    font-size: 1.75vw;
}

.white-text {
    background-color: white;
    padding-bottom: 2vw;
    padding-top: 2vw;
    margin-bottom: 0;
    margin-top: 0;
    font-size: 2.5vw;
}

.white-text-middle{
    background-color: white;
    padding-bottom: 2vw;
    padding-top: 2vw;
    margin-bottom: 0;
    margin-top: 0;
    width: 10vw;
}

.rental-page-link{
    font-size: 3vw;
    text-decoration: none;
    margin-left: 2vw;
    padding: 1vw;
    color: white;
    background-color: black;
    border: 0.5vw solid black;
    border-radius: 1vw;
}

.center-text{
    text-align: center;
}

.signatureCanvas{
    border: 1px solid black;
    width: 50%;
    min-height: 30vw;
}

.header-input {
    height: 3vw;
    margin-bottom:1vw;
}